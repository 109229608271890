<template>
  <v-window v-model="window">
    <div>
      <v-btn
        v-if="window !== 0"
        color="success"
        x-small text
        :to="{name: 'cart-manager-products', params: {username: username}}"
        exact
      >
        Back to product home
      </v-btn>
    </div>
    <v-window-item :key="0">
      <manager
        :extLoading="loading"
        :products="products"
        :username="username"
        @sync-data="asyncData"
        @advanced-click="onAdvancedClick"
        @product-selected="onProductSelected"
      ></manager>
    </v-window-item>

    <v-window-item :key="1">
      <v-container fluid>
        <v-row dense>
          <v-col cols="12" md="6"
            v-for="p in productObj"
            :key="p.id"
          >
            <v-card>
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="cyan"
                elevation="12"
                max-width="calc(100% - 32px)"
              >
                <v-sparkline
                  v-if="false"
                  :labels="labels"
                  :value="value"
                  color="white"
                  line-width="2"
                  padding="16"
                ></v-sparkline>
              </v-sheet>

              <v-card-text class="pt-0">
                <div class="text-h6 font-weight-light mb-2">
                  {{ p.name }}
                  {{ p.salesRecords }}
                </div>
                <div class="subheading font-weight-light grey--text">
                  Sales Performance
                </div>
                <v-divider class="my-2"></v-divider>
                <v-icon
                  class="mr-2"
                  small
                >
                  fas fa-clock
                </v-icon>
                <span class="text-caption grey--text font-weight-light">last sale 26 minutes ago</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-window-item>

    <v-window-item :key="2">
      <product-editor
        :product="selectedProduct"
        :active="window === 2"
        :username="username"
        @syncData="asyncData"
        @cancel-click="onCancelEdit"
        @save-complete="onSaveComplete"
      ></product-editor>
    </v-window-item>
  </v-window>
</template>

<script>
import { mapGetters } from 'vuex'
import Manager from './ProductManager'
import ProductEditor from './ProductEditor'
import Product from '@/classes/Product'

export default {
  props: ['active', 'username', 'sku'],
  data () {
    return {
      products: [],
      loading: false,
      search: null,
      window: 0,
      selectedProduct: null
    }
  },
  computed: {
    ...mapGetters(['user', 'getPageInfo']),
    productObj () {
      return this.products && this.products.map(m => new Product(m))
    },
    organization () {
      return this.getPageInfo(this.username)
    }
  },
  methods: {
    asyncData () {
      this.loading = true
      this.$VBL.store.products.getAll(this.username)
        .then(r => {
          this.products = r.data
          this.windowCheck()
        })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    changeWindow () {
      this.window = this.window === 0 ? 1 : 0
    },
    onProductSelected (prod) {
      this.selectedProduct = prod
    },
    onAdvancedClick () {
      this.window = 2
    },
    onCancelEdit () {
      this.window = 0
    },
    onSaveComplete (p) {
      this.selectedProduct.update(p)
      const x = this.products.find(f => f.id === p.id)
      if (!x) {
        this.products.push(this.selectedProduct)
      }
      if (p.urlTag !== this.sku) {
        this.$router.replace({ params: { sku: p.urlTag, username: this.username } })
      }
    },
    windowCheck () {
      if (this.$route.name === 'cart-manager-product-details') {
        const prod = this.sku === '__new__' ? { organizationId: this.organization.id } : this.products.find(f => f.urlTag === this.sku)
        if (prod) {
          this.selectedProduct = new Product(prod)
          this.window = 2
        }
      } else if (this.$route.name === 'cart-manager-products') {
        // this.selectedProduct = null
        this.window = 0
      }
    }
  },
  watch: {
    active: function (val) {
      if (val) {
        this.asyncData()
      }
    },
    '$route.name': 'windowCheck'
  },
  components: {
    Manager,
    ProductEditor
  },
  mounted () {
    this.asyncData()
  }
}
</script>

<style>

</style>
