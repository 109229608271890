<template>
  <v-card>
    <v-toolbar color="color1 color1Text--text" flat>
      <v-toolbar-title>{{ myProduct.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
          color="color2 color2Text--text"
          fab
          small
          @click.stop="back"
          v-if="false"
        >
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
    </v-toolbar>
    <v-card-text v-if="myProduct" class="pt-0">
      <v-container class="px-0" fluid>
        <v-row dense>
          <!-- BREADCRUMB -->
          <v-col cols="12" class="caption" v-if="parents.length > 0">
            <template v-for="(p, i) in parents">
              <v-btn
                :key="`parent${i}`"
                color="color3"
                small text
                @click.stop="gotoParent(i)"
              >{{ p.title }}</v-btn>
              <span v-if="i < parents.length - 1" :key="`parent${i}-2`" class="mx-3">></span>
            </template>
          </v-col>
          <!-- admin info -->
          <v-col cols="12" class="caption" v-if="!isNew">
            Id: {{myProduct.id}} | Created: {{myProduct.dtCreated ? myProduct.dtCreated.format('LLLL') : 'NA'}} By: {{myProduct.createdBy || 'NA'}}
          </v-col>
          <!-- title -->
          <v-col cols="12">
            <v-text-field
              v-if="false"
              label="Name"
              v-model="myProduct.name"
              :disabled="!editing || !!myProduct.uses"
              hint="This is a name for internal use only"
              :error-messages="nameErrors"
              @blur="$v.myProduct.name.$touch()"
              persistent-hint
              color="color3"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Title"
              v-model="myProduct.title"
              :disabled="!editing || !!myProduct.uses"
              hint="This is the name the public will see"
              :error-messages="titleErrors"
              @blur="$v.myProduct.title.$touch()"
              persistent-hint
              color="color3"
            ></v-text-field>
          </v-col>
          <!-- Images -->
          <v-col cols="12" md="6">
            <div>Images</div>
            <v-row dense>
              <v-col cols="12">
                <image-viewer
                  :images="images"
                  :editing="editing"
                  @delete-image="onDeleteImage"
                ></image-viewer>
              </v-col>
            </v-row>
          </v-col>
          <!-- Description -->
          <v-col cols="12" md="6">
            <div>Description</div>
            <html-editor v-model="myProduct.description" :disabled="!editing" placeholder="Description"></html-editor>
          </v-col>
          <!-- Images Selector -->
          <v-expand-transition>
            <v-col cols="12" v-if="editing">
              <div>Click an image to add it</div>
              <image-library
                :username="username"
                :oId="page.id"
                :exclude="excludes"
                single-row
                no-preview
                @preview-click="onPreviewClick"
              ></image-library>
            </v-col>
          </v-expand-transition>
          <!-- Inventory & Limit -->
          <v-col cols="12" class="d-flex align-center">
            <v-switch
              label="Track Inventory"
              v-model="myProduct.keepInventory"
              :disabled="!editing || !!myParent"
              color="success"
              class="shrink mr-3"
              hide-details
            ></v-switch>
            <v-switch
              key="lSwitch"
              label="Limit Purchases"
              :disabled="!editing"
              @click="onLimitClick"
              color="success"
              class="shrink"
              hide-details
            ></v-switch>
            <v-slide-x-transition group mode="out-in" leave-absolute>
              <v-col v-if="myProduct.hasLimit" style="max-width: 110px" class="d-flex align-center" key="lSwitch">
                <v-select
                  :items="limitOptions"
                  v-model="myProduct.limit"
                  label="Purchase Limit"
                  hide-details
                  :disabled="!editing"
                ></v-select>
              </v-col>
            </v-slide-x-transition>
          </v-col>
          <!-- Delivery && Contact Requirements -->
          <v-col cols="12" md="6">
            <v-select
              :items="[
                {text: 'None', value: 'none'},
                {text: 'Customer Choice', value: 'choice'},
                {text: 'Pick Up', value: 'pickup'},
                {text: 'Ship', value: 'ship'}
              ]"
              v-model="myProduct.delivery"
              label="Delivery"
              :disabled="!editing"
              color="color3"
              item-color="color3"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              :items="[
                {text: 'None', value: 'none'},
                {text: 'Name', value: 'name'},
                {text: 'Contact Info', value: 'contact'},
                {text: 'Shipping Info', value: 'ship'}
              ]"
              v-model="myProduct.contactReq"
              label="Contact Requirements"
              :disabled="!editing"
              color="color3"
              item-color="color3"
            ></v-select>
          </v-col>
          <!-- props -->
          <v-col cols="12" v-if="user && user.vbl">
            <v-combobox
              label="Props (Only displayed for VBL Admin)"
              v-model="myProduct.props"
              :disabled="!editing"
              chips small-chips multiple deletable-chips
            ></v-combobox>
          </v-col>
          <!-- options -->
          <v-col cols="12">
            <v-slide-x-transition group>
              <template v-for="(opt, i) in myProduct.options.filter(f => !f.deleted)">
                <simple-option
                  :key="`row-${i}`"
                  :opt="opt"
                  :editing="editing"
                  :showLabel="myProduct.options.length > 1 || parents.length > 0"
                  :depth="1"
                  @advanced-click="editChild"
                  :i="i"
                  :optArray="myProduct.options.filter(f => !f.deleted)"
                  @option-swap="onOptionSwap"
                ></simple-option>
                <v-divider :key="`div-${i}`" v-if="i+1 !== myProduct.options.length && false"></v-divider>
              </template>
            </v-slide-x-transition>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn color="color3" x-small text @click.stop="addOption" :disabled="!editing">add Option</v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-checkbox
              label="Show In My Store"
              v-model="myProduct.showInStore"
              hide-details
              :disabled="!editing"
              color="success"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" v-if="user && user.vbl">
            <v-btn color="color3" @click.stop="showJson = !showJson" small text>
              {{ showJson ? 'hide' : 'show' }} Json
            </v-btn>
            <json-props-editor
              v-if="showJson"
              ref="jsonEditor"
              :active="true"
              :product="myProduct"
              :disabled="!editing"
            ></json-props-editor>
          </v-col>
        </v-row>
        <v-expand-transition>
          <div v-if="error">
            <v-alert type="error" :value="true">
              Oops: {{error}}
            </v-alert>
          </div>
        </v-expand-transition>
      </v-container>
    </v-card-text>
    <!-- BUTTONS -->
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <span>Save</span>
        <template v-slot:activator="{ on }">
          <v-fab-transition>
            <v-btn
              v-on="on"
              v-if="editing"
              color="success white--text"
              key="save"
              fab
              :small="$vuetify.breakpoint.xsOnly"
              fixed bottom right
              @click.stop="onSaveClick"
              :loading="loading"
              :disabled="!valid || !dirty"
              :class="{'mr-12': $vuetify.breakpoint.xsOnly, 'mr-16': !$vuetify.breakpoint.xsOnly}"
            >
              <v-icon :small="$vuetify.breakpoint.xsOnly">fas fa-save</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
      </v-tooltip>
      <v-tooltip top>
        <span>{{activeFab.tooltip}}</span>
        <template v-slot:activator="{ on }">
          <v-fab-transition>
            <v-btn
              v-on="on"
              :color="activeFab.color"
              :key="activeFab.icon"
              fab
              :small="$vuetify.breakpoint.xsOnly"
              fixed bottom right
              @click.stop="activeFab.onClick"
            >
              <v-icon :small="$vuetify.breakpoint.xsOnly">{{activeFab.icon}}</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
import Product from '@/classes/Product'
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import HtmlEditor from '@/components/Utils/HtmlEditor.vue'
import SimpleOption from './SimpleOption'
const JsonPropsEditor = () => import('@/components/Store/JsonPropsEditor.vue')
const ImageLibrary = () => import('@/components/Organization/OrganizationImageLibrary.vue')
const ImageViewer = () => import('@/components/Utils/ImageLibraryViewer.vue')

export default {
  mixins: [validationMixin],
  props: ['product', 'active', 'username'],
  data () {
    return {
      iProduct: null,
      editing: null,
      loading: false,
      error: null,
      parents: [],
      showJson: false
    }
  },
  validations: {
    myProduct: {
      name: { required },
      title: { required }
    },
    discountValue: { required }
  },
  computed: {
    ...mapGetters(['user', 'getPageInfo']),
    page () {
      return this.getPageInfo(this.username)
    },
    nameErrors () {
      const errors = []
      if (!this.$v.myProduct.name.$dirty) return errors
      !this.$v.myProduct.name.required && errors.push('A name is required')
      return errors
    },
    titleErrors () {
      const errors = []
      if (!this.$v.myProduct.title.$dirty) return errors
      !this.$v.myProduct.title.required && errors.push('A title is required')
      return errors
    },
    isNew () {
      return this.product && !this.product.id
    },
    myProduct () {
      return this.editing ? this.iProduct : this.product
    },
    activeFab () {
      return !this.editing ? {
        color: 'color3Text color3--text',
        icon: 'fas fa-pencil-alt',
        onClick: this.edit,
        tooltip: 'Edit'
      } : {
        color: 'red white--text',
        icon: 'fas fa-times',
        onClick: this.cancel,
        tooltip: 'Cancel'
      }
    },
    dirty () {
      return JSON.stringify(this.product.dto) !== JSON.stringify(this.iProduct.dto)
    },
    optionsValid () {
      return this.myProduct.options.filter(f => f.price > 0 || f.price < 0).length > 0 || (this.user && this.user.vbl)
    },
    valid () {
      return this.nameErrors.length + this.titleErrors.length === 0 && this.optionsValid
    },
    dto () {
      return this.topLevelProduct.dto
    },
    myParent () {
      return this.parents.length > 0 ? this.parents[this.parents.length - 1] : false
    },
    topLevelProduct () {
      return this.parents.length > 0 ? this.parents[0] : this.myProduct
    },
    images () {
      return this.myProduct ? this.myProduct.jsonProps.imageLibrary.images : []
    },
    excludes () {
      return this.images.map(m => m.url)
    },
    limitOptions () {
      return [...Array(99).keys()].map(x => ++x)
    }
  },
  methods: {
    init () {
      this.parents = []
      var dto = this.product && this.product.dto ? JSON.stringify(this.product.dto) : false
      this.iProduct = new Product(dto)
      if (this.isNew) {
        this.edit()
      }
    },
    edit () {
      if (this.iProduct.options.length === 0) this.iProduct.addOption()
      this.editing = true
    },
    editChild (child) {
      if (child) {
        // var dto = JSON.stringify(child.dto)
        // const newI = new Product(dto)
        this.parents.push(this.iProduct)
        this.iProduct = child
      }
    },
    gotoParent (i) {
      this.iProduct = this.parents[i]
      this.parents.length = i
    },
    formatPrice (opt) {
      opt.price = parseFloat(opt.price).toFixed(2)
    },
    back () {
      this.$emit('cancel-click')
    },
    cancel () {
      this.editing = false
      if (this.isNew) {
        this.$router.push({ name: 'cart-manager-products', params: { username: this.username } })
      } else {
        this.init()
      }
    },
    addOption () {
      const dto = this.iProduct && this.iProduct.price ? { price: this.iProduct.price } : null
      const o = this.myProduct.addOption(dto)
      this.formatPrice(o)
    },
    onSaveClick () {
      // this.editing = false
      this.loading = true
      this.$VBL.store.products.post(this.username, this.dto)
        .then(r => {
          this.$emit('save-complete', r.data)
          this.cancel()
        })
        .catch(e => {
          console.log(e.response)
          this.error = e.response.data
        })
        .finally(() => { this.loading = false })
    },
    onPreviewClick (image) {
      this.myProduct.jsonProps.imageLibrary.images.push(image)
      this.$refs.jsonEditor && this.$refs.jsonEditor.init()
    },
    onDeleteImage (id) {
      console.log(id)
    },
    onLimitClick () {
      if (this.myProduct.hasLimit) {
        this.myProduct.limit = 0
      } else {
        this.myProduct.limit = 1
      }
    },
    onOptionSwap () {
      this.opt.correctOrder()
    }
  },
  watch: {
    active: function (v) {
      v && this.init()
    }
  },
  mounted () {
    if (this.active) this.init()
  },
  components: {
    HtmlEditor,
    JsonPropsEditor,
    SimpleOption,
    ImageLibrary,
    ImageViewer
  }
}
</script>

<style scoped>

</style>
