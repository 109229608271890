<template>
<v-menu
    open-on-hover
    top
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="color3"
        dark
        v-bind="attrs"
        v-on="on"
        text icon small
        :disabled="disabled"
      >
        <v-icon>fas fa-ellipsis-v</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        @click.stop="item.click"
      >
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: ['disabled', 'up', 'down'],
  computed: {
    items () {
      return [
        { text: 'Advanced Edit', click: () => { this.$emit('advanced-click') }, show: true },
        { text: 'Delete', click: () => { this.$emit('delete-click') }, show: true },
        { text: 'Move Up', click: () => { this.$emit('move-up-click') }, show: this.upX },
        { text: 'Move Down', click: () => { this.$emit('move-down-click') }, show: this.downX }
      ].filter(f => f.show)
    }
  },
  methods: {

  }
}
</script>
