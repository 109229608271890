<template>
  <v-row dense>
    <v-col :cols="cols[0]">
      <v-text-field
        v-if="showLabel"
        label="Option Label"
        v-model="opt.title"
        :disabled="!editing"
        persistent-hint
        color="color3"
        hide-details
      ></v-text-field>
    </v-col>
    <v-col :cols="cols[1]">
      <v-text-field
        label="Price"
        v-model="opt.price"
        :disabled="!editing"
        persistent-hint
        color="color3"
        type="number"
        @blur="formatPrice(opt)"
        prefix="$"
        hide-details
      >
        <template v-slot:append-outer v-if="cols[2] === 0">
          <simple-option-menu
            :disabled="!editing"
            @advanced-click="onAdvancedClick"
            @delete-click="onDeleteClick"
          ></simple-option-menu>
        </template>
      </v-text-field>
    </v-col>
    <v-col v-if="cols[2] > 0" :cols="cols[2]">
      <v-text-field
        label="Current Inventory"
        v-model="opt.inventory"
        :disabled="!editing"
        persistent-hint
        color="color3"
        type="number"
        hide-details
      >
        <template v-slot:append-outer>
          <simple-option-menu
            :disabled="!editing"
            @advanced-click="onAdvancedClick"
            @delete-click="onDeleteClick"
            @move-up-click="moveUp(i)"
            @move-down-click="moveDown(i)"
            :up="i > 0"
            :down="i < optArray.length - 1"
          ></simple-option-menu>
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="12" v-if="opt.options.length > 0" class="pl-8">
      <simple-option
        v-for="(opt2, i) in opt.options.filter(f => !f.deleted)"
        :key="`row-${i}`"
        :opt="opt2"
        :editing="editing"
        :showLabel="true"
        :depth="depth + 1"
        @advanced-click="onAdvancedClick"
        :i="i"
        :optArray="opt.options.filter(f => !f.deleted)"
        @option-swap="onOptionSwap"
      ></simple-option>
    </v-col>
    <vbl-confirm-dialog
      :ask="confirmDelete"
      @deny="confirmDelete = false"
      @confirm="onDeleteConfirm"
    ></vbl-confirm-dialog>
  </v-row>
</template>

<script>
import SimpleOption from './SimpleOption'
import SimpleOptionMenu from './SimpleOptionMenu'

export default {
  name: 'simple-option',
  props: ['opt', 'showLabel', 'editing', 'depth', 'i', 'optArray'],
  data () {
    return {
      confirmDelete: false
    }
  },
  computed: {
    pad () {
      if (this.depth === 0) return null
      const x = this.depth * 4
      return x > 16 ? 'pl-16' : `pl-${x}`
    },
    cols () {
      return this.opt.keepInventory && this.opt.options.length === 0 ? this.$vuetify.breakpoint.xsOnly ? [12, 6, 6] : [4, 4, 4] : [6, 6, 0]
    }
  },
  methods: {
    formatPrice (opt) {
      opt.price = parseFloat(opt.price).toFixed(2)
    },
    onAdvancedClick () {
      this.$emit('advanced-click', this.opt)
    },
    onDeleteClick () {
      this.confirmDelete = true
    },
    onDeleteConfirm () {
      this.opt.deleted = true
      this.confirmDelete = false
    },
    moveUp (i) {
      const a = this.opt
      const b = this.optArray[i - 1]
      if (a && b) {
        this.swap(a, b)
      }
    },
    moveDown (i) {
      const a = this.opt
      const b = this.optArray[i + 1]
      if (a && b) {
        this.swap(a, b)
      }
    },
    swap (a, b) {
      const x = a.myOrder
      const y = b.myOrder
      a.myOrder = y
      b.myOrder = x
      this.$emit('option-swap')
    },
    onOptionSwap () {
      this.opt.correctOrder()
    }
  },
  components: {
    SimpleOption,
    SimpleOptionMenu
  }
}
</script>
