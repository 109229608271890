<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
       <product-admin :username="username" :active="true" :sku="sku"></product-admin>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductAdmin from '@/components/Store/ProductAdmin'

export default {
  props: ['username', 'sku'],
  components: {
    ProductAdmin
  }
}
</script>

<style>

</style>
